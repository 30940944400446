import { findParentIf } from "../../../utils/cards_functions";
import { doesIfInstrTypeExist } from "../TestSteps/dropdowns/data/if";

const initialState = {
  cards: [],
  selectedCards: [],
  statusMessage: "",
  savedGlobalData: [],
  existingGroups: [],
  conditionActive: [],
  recentActions: [],
  undoedActions: [],
  linkedLocalDataId: null,
  currentTestcaseName: null,
  sessionIdForNewTC: null,

  // States for keeping track of selected card options
  isGroupingPossible: false,
  isLoopingPossible: false,
  isDeletingPossible: false,

  groupingSelectedCards: false,
  loopingSelectedCards: false,

  exitingIfelse: null,
  screenshotStep: null,
  isExpandedStep: null,
};

const updateCard = (cards, step_id, updateData) => {
  return cards.map((card) => {
    if (card.step_id === step_id) {
      // Update the card with the new data
      let updatedCard = {
        ...card,
        inst: {
          ...card.inst,
          ...updateData.inst,
        },
        data: updateData.data,
        status: updateData.status,
        ordinal: updateData.ordinal,
        message: updateData.message,
        do_ordinal_selection: updateData.do_ordinal_selection,
      };

      // Check and add group_id and group_hash if they exist in action.payload
      if (updateData.group_id) {
        updatedCard.group_id = updateData.group_id;
      }
      if (updateData.group_hash) {
        updatedCard.group_hash = updateData.group_hash;
      }

      return updatedCard;
    }

    // Recursively update sub_steps if present
    if (card.sub_steps) {
      return {
        ...card,
        sub_steps: updateCard(card.sub_steps, step_id, updateData),
      };
    }

    return card;
  });
};

const handleDeleteCard = (cards, deletedCardStepId) => {
  // Delete the card and update step_ids
  const deleteCard = (cards, deletedCardStepId) => {
    const deleteAndUpdateIds = (cardsArray, parentId = "") => {
      let nextIndex = 1;
      return cardsArray.reduce((acc, card) => {
        if (card.step_id === deletedCardStepId) {
          return acc; // Skip this card as it's being deleted
        }
        const newParts = parentId
          ? [...parentId.split("."), nextIndex.toString()]
          : [nextIndex.toString()];
        const newStepId = newParts.join(".");

        nextIndex++;

        const updatedCard = { ...card, step_id: newStepId };
        if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
          updatedCard.sub_steps = deleteAndUpdateIds(
            updatedCard.sub_steps,
            newStepId
          );
        }
        return [...acc, updatedCard];
      }, []);
    };
    return deleteAndUpdateIds(cards);
  };
  const semiUpdatedCards = deleteCard(cards, deletedCardStepId);

  console.log("semiUpdatedCards", semiUpdatedCards);
  // Function to update parent_if for all cards
  const updateParentIf = (cards) => {
    return cards.map((card) => {
      let updatedCard = { ...card };
      if (updatedCard.inst && updatedCard.inst.parent_if) {
        const newParentIf = findParentIf(semiUpdatedCards, updatedCard.step_id);
        console.log(
          "paren_if",
          newParentIf,
          semiUpdatedCards,
          updatedCard.step_id
        );
        updatedCard.inst = {
          ...updatedCard.inst,
          parent_if: newParentIf,
        };
      }
      if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
        updatedCard.sub_steps = updateParentIf(updatedCard.sub_steps);
      }

      return updatedCard;
    });
  };

  // Update parent_if for all cards
  const finalUpdatedCards = updateParentIf(semiUpdatedCards);

  console.log("finalUpdatedCards", finalUpdatedCards);
  return finalUpdatedCards;
};

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_CARDS":
      console.log("RESET_CARDS");
      return {
        ...state,
        cards: [],
        recentActions: [],
        undoneActions: [],
      };

    case "SET_SESSION_ID_FOR_NEW_TC":
      console.log("SET_SESSION_ID_FOR_NEW_TC");
      return {
        ...state,
        sessionIdForNewTC: action.payload,
      };

    case "ADD_CARD":
      console.log("ADD_CARD", action.payload);
      const {
        inst = {
          instr_type: null,
          instr: "",
          label_type: null,
          label: "",
          contextual_label_type: null,
          contextual_label: "",
        },
        step_id,
        data,
        status,
        ordinal,
        do_ordinal_selection,
        sub_steps = [],
        message,
        screenshot_before,
        screenshot_after,
        screenshot_main,
        screenshot_thumbnail,
      } = action.payload;

      const cardDetails = {
        inst,
        step_id,
        data,
        status,
        ordinal,
        do_ordinal_selection,
        sub_steps,
        parent_id: null,
        message,
        screenshot_before,
        screenshot_after,
        screenshot_main,
        screenshot_thumbnail,
      };
      if (action.payload.group_id) {
        // console.log("group_id", action.payload.group_id);
        cardDetails.group_id = action.payload.group_id;
      }
      if (action.payload.group_hash) {
        cardDetails.group_hash = action.payload.group_hash;
      }

      if (action.payload.condition_instr) {
        cardDetails.condition_instr = action.payload.condition_instr;
      }
      if (action.payload.instr_type) {
        cardDetails.instr_type = action.payload.instr_type;
      }

      const addCardToHierarchy = (cards, newCard) => {
        const stepIdParts = newCard.step_id.split(".");
        const parentStepId = stepIdParts.slice(0, -1).join(".");
        const currentStepId = stepIdParts.join(".");

        if (parentStepId === "") {
          // This is a top-level card
          return insertCard(cards, newCard);
        }

        return cards.map((card) => {
          if (card.step_id === parentStepId) {
            const updatedCard = {
              ...card,
              sub_steps: insertCard(
                card.sub_steps ? [...card.sub_steps] : [],
                newCard
              ),
            };
            newCard.parent_id = parentStepId;
            return updatedCard;
          }

          if (card.sub_steps && card.sub_steps.length > 0) {
            return {
              ...card,
              sub_steps: addCardToHierarchy(card.sub_steps, newCard),
            };
          }

          return card;
        });
      };

      const insertCard = (cards, newCard) => {
        const existingIndex = cards.findIndex(
          (card) => card.step_id === newCard.step_id
        );
        if (existingIndex !== -1) {
          const newSubSteps = [
            ...cards.slice(0, existingIndex),
            newCard,
            ...cards.slice(existingIndex),
          ];
          return adjustStepIds(newSubSteps, existingIndex + 1);
        } else {
          return [...cards, newCard];
        }
      };

      const adjustStepIds = (cards, startIndex) => {
        return cards.map((card, index) => {
          if (index >= startIndex) {
            const stepIdParts = card.step_id.split(".");
            stepIdParts[stepIdParts.length - 1] = (
              parseInt(stepIdParts[stepIdParts.length - 1], 10) + 1
            ).toString();
            const newStepId = stepIdParts.join(".");

            return {
              ...card,
              step_id: newStepId,
              sub_steps: adjustSubStepIds(
                card.sub_steps ? [...card.sub_steps] : [],
                newStepId
              ),
            };
          }
          return card;
        });
      };

      const adjustSubStepIds = (subSteps, parentStepId) => {
        return subSteps.map((subStep, index) => {
          const newSubStepId = `${parentStepId}.${index + 1}`;

          return {
            ...subStep,
            step_id: newSubStepId,
            sub_steps: adjustSubStepIds(
              subStep.sub_steps ? [...subStep.sub_steps] : [],
              newSubStepId
            ),
          };
        });
      };

      const newCards = addCardToHierarchy([...state.cards], cardDetails);

      // Function to update parent_if for all cards
      const updateParentIf = (cards) => {
        return cards.map((card) => {
          let updatedCard = { ...card };
          if (updatedCard.inst && updatedCard.inst.parent_if) {
            const newParentIf = findParentIf(newCards, updatedCard.step_id);
            updatedCard.inst = {
              ...updatedCard.inst,
              parent_if: newParentIf,
            };
          }
          if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
            updatedCard.sub_steps = updateParentIf(updatedCard.sub_steps);
          }
          return updatedCard;
        });
      };

      // Update parent_if for all cards
      const finalUpdatedCards = updateParentIf(newCards);

      console.log("ADD_CARD", finalUpdatedCards);
      console.log("ADD_CARD", newCards);
      return {
        ...state,
        cards: finalUpdatedCards,
      };

    case "DELETE_CARD":
      console.log("DELETE_CARDS");
      return {
        ...state,
        cards: handleDeleteCard(state.cards, action.payload.id),
      };

    case "EDIT_CARD":
      const updatedCards = updateCard(
        state.cards,
        action.payload.step_id,
        action.payload
      );

      return {
        ...state,
        cards: updatedCards,
      };

    case "SET_STATUS_MESSAGE":
      return {
        ...state,
        statusMessage: action.payload,
      };

    case "SET_CONDITION_ACTIVE":
      // console.log("action.payload", action.payload);
      return {
        ...state,
        conditionActive: [...state.conditionActive, action.payload],
      };

    case "SET_ALL_CONDITION_ACTIVE":
      // console.log("action.payload", action.payload);
      return {
        ...state,
        conditionActive: [...action.payload],
      };

    case "POP_CONDITION_ACTIVE":
      return {
        ...state,
        conditionActive: state.conditionActive.slice(0, -1),
      };

    case "POP_ALL_CONDITION_ACTIVE":
      return {
        ...state,
        conditionActive: [],
      };

    case "SET_LINKED_LOCAL_DATA_ID":
      return {
        ...state,
        linkedLocalDataId: action.payload,
      };

    case "SET_CURRENT_TESTCASE_NAME":
      return {
        ...state,
        currentTestcaseName: action.payload,
      };

    case "SET_EXISTING_GROUPS":
      return {
        ...state,
        existingGroups: action.payload,
      };

    case "ADD_TO_SELECTED_CARD":
      return {
        ...state,
        selectedCards: [...state.selectedCards, action.payload],
      };

    case "REMOVE_SELECTED_CARD":
      return {
        ...state,
        selectedCards: state.selectedCards.filter(
          (step_id) => step_id !== action.payload
        ),
      };

    case "REMOVE_ALL_SELECTED_CARDS":
      return {
        ...state,
        selectedCards: [],
      };

    case "IS_GROUP_POSSIBLE_FOR_SELECTED_CARDS":
      return {
        ...state,
        isGroupingPossible: action.payload,
      };

    case "IS_LOOP_POSSIBLE_FOR_SELECTED_CARDS":
      return {
        ...state,
        isLoopingPossible: action.payload,
      };

    case "IS_DELETE_POSSIBLE_FOR_SELECTED_CARDS":
      return {
        ...state,
        isDeletingPossible: action.payload,
      };

    case "GROUPING_SELECTED_CARDS":
      return {
        ...state,
        groupingSelectedCards: action.payload,
      };

    case "LOOPING_SELECTED_CARDS":
      return {
        ...state,
        loopingSelectedCards: action.payload,
      };

    case "SET_EXITING_IFELSE":
      return {
        ...state,
        exitingIfelse: action.payload,
      };

    case "ADD_RECENT_ACTION":
      return {
        ...state,
        recentActions: [...state.recentActions, action.payload],
      };

    case "ADD_UNDOED_ACTION":
      return {
        ...state,
        undoedActions: [...state.undoedActions, action.payload],
      };

    case "POP_RECENT_ACTION":
      return {
        ...state,
        recentActions: state.recentActions.slice(0, -1),
      };

    case "POP_UNDOED_ACTION":
      return {
        ...state,
        undoedActions: state.undoedActions.slice(0, -1),
      };

    case "POP_ALL_UNDOED_ACTIONS":
      return {
        ...state,
        undoedActions: [],
      };

    case "POP_ALL_RECENT_ACTIONS":
      return {
        ...state,
        recentActions: [],
      };

    case "SCREENSHOT_STEP":
      return {
        ...state,
        screenshotStep: action.payload,
      };

    case "IS_EXPANDED_STEP":
      return {
        ...state,
        isExpandedStep: action.payload,
      };

    default:
      return state;
  }
};

export default cardsReducer;
